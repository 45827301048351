export default (analyticsResult) => {
    const lastMontyAnalytics = analyticsResult;
    let apo = new Object()
    lastMontyAnalytics.forEach(function(datapoint){
        Object.keys(datapoint.impressions).filter(k=>k.indexOf('total') === -1).forEach(function(ruleId){
          if(apo.hasOwnProperty(ruleId)){
            apo[ruleId].impressions += datapoint.impressions[ruleId].totalCount
          }else{
            apo[ruleId] = new Object()
            apo[ruleId]['impressions'] = datapoint.impressions[ruleId].totalCount
            apo[ruleId]['orderCreate'] = 0
            apo[ruleId]['revenue'] = 0
            apo[ruleId]['product'] = new Object()
            apo[ruleId]['version'] = new Object()
          }
          Object.keys(datapoint.impressions[ruleId]).filter(k=>k.indexOf('total') === -1).forEach(function(version){
            if("A" === version || "B" === version){
              if(apo[ruleId].version.hasOwnProperty(version)){
                apo[ruleId].version[version]['totalImpressionCount'] += datapoint.impressions[ruleId][version].totalCount
              }else{
                apo[ruleId].version[version] = new Object()
                apo[ruleId].version[version]['totalImpressionCount'] = datapoint.impressions[ruleId][version].totalCount
                apo[ruleId].version[version]['totalCount'] = 0
                apo[ruleId].version[version]['totalUpsoldRevenue'] = 0
                
              }
            }
          })
        })
        Object.keys(datapoint.orderCreate).filter(k=>k.indexOf('total') === -1).forEach(function(ruleId){
          if(apo.hasOwnProperty(ruleId)){
            apo[ruleId].orderCreate += datapoint.orderCreate[ruleId].totalCount
            apo[ruleId].revenue += datapoint.orderCreate[ruleId].totalUpsoldRevenue
           
            Object.keys(datapoint.orderCreate[ruleId]).filter(k=>k.indexOf('total') === -1).forEach(function(productId){
              
                if("A" === productId || "B" === productId){
                  if(apo[ruleId].version.hasOwnProperty(productId)){
                    apo[ruleId].version[productId]['totalCount'] += datapoint.orderCreate[ruleId][productId].totalCount
                    apo[ruleId].version[productId]['totalUpsoldRevenue'] += datapoint.orderCreate[ruleId][productId].totalUpsoldRevenue
                    
                  }else{
                    apo[ruleId].version[productId] = new Object()
                    apo[ruleId].version[productId]['totalImpressionCount'] = 0
                    apo[ruleId].version[productId]['totalCount'] = datapoint.orderCreate[ruleId][productId].totalCount
                    apo[ruleId].version[productId]['totalUpsoldRevenue'] = datapoint.orderCreate[ruleId][productId].totalUpsoldRevenue
                    
                  }
                }else{
                  if(apo[ruleId].product.hasOwnProperty(productId)){
                    // total count per product shouldn't larger than total count per rule, total revenue per product shouldn't larger than total revenue per rule
                    if( datapoint.orderCreate[ruleId].totalUpsoldRevenue >= datapoint.orderCreate[ruleId][productId].totalUpsoldRevenue){
                     
                        apo[ruleId].product[productId]['totalCount'] += datapoint.orderCreate[ruleId][productId].totalCount
                        apo[ruleId].product[productId]['totalUpsoldRevenue'] += datapoint.orderCreate[ruleId][productId].totalUpsoldRevenue
                     
                    }
                  }else{
                    if( datapoint.orderCreate[ruleId].totalUpsoldRevenue >= datapoint.orderCreate[ruleId][productId].totalUpsoldRevenue){
                      
                        apo[ruleId].product[productId] = new Object()
                        apo[ruleId].product[productId]['name'] = datapoint.orderCreate[ruleId][productId].name
                        apo[ruleId].product[productId]['totalCount'] = datapoint.orderCreate[ruleId][productId].totalCount
                        apo[ruleId].product[productId]['totalUpsoldRevenue'] = datapoint.orderCreate[ruleId][productId].totalUpsoldRevenue
                     
                    }
                  }
              }
              
            })
            
          }
        })
    })
    
    for(let ruleId in apo){
      apo[ruleId]['conversion'] = ((apo[ruleId]['orderCreate']/apo[ruleId]['impressions'])*100).toFixed(2).toString()+"%"
      let totalRevenue = 0
      Object.keys(apo[ruleId].product).forEach(function(productId){
        totalRevenue += apo[ruleId].product[productId]['totalUpsoldRevenue']
      })
      Object.keys(apo[ruleId].product).forEach(function(productId){
        if(totalRevenue === 0){
          apo[ruleId].product[productId]['share'] = "0%"
        }else{
          apo[ruleId].product[productId]['share'] = ((apo[ruleId].product[productId]['totalUpsoldRevenue']/totalRevenue)*100).toFixed(1)+"%"
        }
      })
      Object.keys(apo[ruleId].version).forEach(function(version){
        apo[ruleId].version[version]['conversion'] = ((apo[ruleId].version[version]['totalCount']/apo[ruleId].version[version]['totalImpressionCount'])*100).toFixed(2).toString()+"%"
        
      })
    }

    return apo
}