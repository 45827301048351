import React, { useState, useCallback } from "react"
import {
  Button,
  Toast,
  Frame,
} from "@shopify/polaris"
import styled from "styled-components"
import sendFeedbackMessage from "../../../helpers/sendFeedbackMessage"
import InfoModal from "../../../components/infoModal"
const FrameWrapper = styled.div`
  .Polaris-Frame {
    max-height: 0;
    min-height: 0;
  }
`
const Proposal = (props) => {
  const [email, setEmail] = useState(null)
  const [feedback, setFeedback] = useState(null)
  const [toastActive, setToastActive] = useState(null)
  const toggleToastActive = () => setToastActive(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [requestModalActive, setRequestModalActive] = useState(false)
  const handleRequestModalChange = useCallback(
    () => setRequestModalActive(!requestModalActive),
    [requestModalActive]
  )
  const handleEmailChange = useCallback((newValue) => setEmail(newValue), [])
  const handleFeedbackChange = useCallback(
    (newValue) => setFeedback(newValue),
    []
  )
  const sendRequest = async () => {
    if (!validateEmail(email)) {
      setError("Please enter a valid email address")
    } else {
      setLoading(true)
      const result = await sendFeedbackMessage(
        {
          feedback: feedback,
          email: email,
          type: "proposal"
        },
        props.token,
        props.shop,
        props.host
      )
      setLoading(false)
      if (200 === result.status) {
        setRequestModalActive(false)
        setToastActive("Success")
      } else {
        setToastActive("Error")
      }
    }
  }
  const validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/
    return re.test(email)
  }
  const toastMarkup =
    toastActive && "Success" === toastActive ? (
      <Toast
        content="Request sent"
        onDismiss={toggleToastActive}
      />
    ) : toastActive && "Error" === toastActive ? (
      <Toast
        content="Something is wrong. Please contact us in the chat on the bottom right."
        onDismiss={toggleToastActive}
        error
      />
    ) : null
  return (
    <div>
      <InfoModal
        activator={
          <Button onClick={handleRequestModalChange}  variant="primary">
            Evaluate upsell strategy
          </Button>
        }
        requestModalActive={requestModalActive}
        handleRequestModalChange={handleRequestModalChange}
        title="Get a review of your upsell approach"
        infoText="Our team can review your current upsell approach and suggest improvements based on best practices to achieve your goals. What are you looking to achieve with your upsells?"
        emailText="Where can we reach you with the proposal:"
        handleFeedbackChange={handleFeedbackChange}
        handleEmailChange={handleEmailChange}
        sendRequest={sendRequest}
        loading={loading}
        feedback={feedback}
        email={email}
        error={error}
        type="proposal"
      />
      <FrameWrapper>
        <Frame>{toastMarkup}</Frame>
      </FrameWrapper>
    </div>
  );
}

export default Proposal
